import './bootstrap';
import '../css/app.css';

import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';

const appName = import.meta.env.VITE_APP_NAME || 'Arrow Reporting';

//import and set the license key
import * as wjcCore from '@mescius/wijmo';
wjcCore.setLicenseKey('cloud-reporting.test|reports.arrowpos.com|staging-reports.arrowpos.com,581894554493877#B0cJMLiEjd4IDMyIiOiIXZ6JCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPnRTbBZVZNtiaBZWWhpWS7hmbkJUdJNDMsRUc7hFZvUnUx5mcV3CSrkTeyRUMCFXax24KrRFZntCOyZzRMt6LDl4ZQNDW496aUtUOaJnNZtCTLJzM6QXeyQFcstWU0lkZBRmQ6IWdBllaZ3yaRl4UMlnNqlHRvBFeaZmYGt6T59mRhdmYBBDVjhVSYJjQ7JTTrJkbvsGUZlmQxQ5aNd6ayA7ZqBjTB5EcL9Ea6MmQrpFbrx4M9ZjT9w6KpdzYrM7VaRUSvJjQk5EejhXZaF4RzZlV7YFcz5kNEdTO83yaIlUREtme5I7R8QnQ9cnVwAlYaFFN8g4ZrgXesV4Tuh7UtJzYT3ib9dkdoZ5R5VlcahFWFN6ZEdkZndXeFtUauNFRwc6V6p7QTp6YJhXY544b7QUMLlDUD5GNDR7QBdzLiJXQkxkbEVjZ0ZGNwRUOkZGWWJ5T7gWWiojITJCLiMDMGFkRykjI0ICSiwSNwQDM8IDNxQTM0IicfJye35XX3JSSwIjUiojIDJCLi86bpNnblRHeFBCI4VWZoNFelxmRg2Wbql6ViojIOJyes4nI5kkTRJiOiMkIsIibvl6cuVGd8VEIgIXZ7VWaWRncvBXZSBybtpWaXJiOi8kI1xSfis4N8gkI0IyQiwiIu3Waz9WZ4hXRgAydvJVa4xWdNBybtpWaXJiOi8kI1xSfiQjR6QkI0IyQiwiIu3Waz9WZ4hXRgACUBx4TgAybtpWaXJiOi8kI1xSfiMzQwIkI0IyQiwiIlJ7bDBybtpWaXJiOi8kI1xSfiUFO7EkI0IyQiwiIu3Waz9WZ4hXRgACdyFGaDxWYpNmbh9WaGBybtpWaXJiOi8kI1tlOiQmcQJCLiIzM6IDMxAyMwUDM4IDMyIiOiQncDJCLi46bj9ycvB7dvJnch9yc4J7bwVmctcmbpdWY4NHLt36YuM7bwd7byJXYuMHdy3GclJHL4NXZ49yZulGdy3GclJXLkV7bsNmI0IyctRkIsIyUPB5dvJncBJiOiEmTDJCLiczN8MTO4QTN5QTO8EDO5IiOiQWSiwSfdtlOgcIb')

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';

import React from "react";
Bugsnag.start({
    apiKey: 'ca0690f2944402232e8179fca029bb2a',
    plugins: [new BugsnagPluginReact()]
})
BugsnagPerformance.start({ apiKey: 'ca0690f2944402232e8179fca029bb2a' });

const ErrorBoundary = Bugsnag.getPlugin('react')
    .createErrorBoundary(React)

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx')),
    setup({ el, App, props }) {
        const root = createRoot(el);

        root.render(<ErrorBoundary><App {...props} /></ErrorBoundary>);
    },
    progress: {
        color: '#4B5563',
    },
});
